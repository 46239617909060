// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  redirect_url: 'https://admin-portal-dev.mp.directsale.vn/authentication/login',
  auth_url: 'https://sso-dev.directsale.vn',
  client_id: 'clientapp',
  client_secret: '123456',
  api_url: 'https://admin-be-dev.mp.directsale.vn/api/v1',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
