import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from './authentication/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(
    private translate: TranslateService,
    private authService: AuthenticationService,
  ) {
    this.translate.setDefaultLang('en');
  }

  ngOnInit() {
    setInterval(() => {
      this.authService.checkExpireTime();
    }, 600000);
  }
}
