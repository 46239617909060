import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {FullComponent} from './layouts/full/full.component';
import {BlankComponent} from './layouts/blank/blank.component';
import {AuthGuard} from './authentication/authentication.guard';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'publishers', pathMatch: 'full'},
      {
        path: 'publishers',
        loadChildren: './modules/publisher/publisher.module#PublisherModule'
      },
      {
        path: 'companies',
        loadChildren: './modules/company/company.module#CompanyModule'
      },
      {
        path: 'campaigns',
        loadChildren: './modules/campaign/campaign.module#CampaignModule'
      },
      {
        path: 'deeplink',
        loadChildren: './modules/deep-link/deep-link.module#DeepLinkModule'
      },
      {
        path: 'role',
        loadChildren: './modules/role/role.module#RoleModule'
      },
      {
        path: 'user',
        loadChildren: './modules/user/user.module#UserModule'
      },
      {
        path: 'contracts',
        loadChildren: './modules/contract/contract.module#ContractModule'
      },
      {
        path: 'ad-space',
        loadChildren: './modules/ad-space/ad-space.module#AdSpaceModule'
      },
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/authentication/404'
  }
];
