import {Injectable} from '@angular/core';

import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable()

export class PortTypeInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const portTypeReq = req.clone({
      headers: req.headers.set('X-Port-Type', 'ADM')
    });

    return next.handle(portTypeReq);
  }
}
