import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Publisher',
    icon: 'icon-people',
    class: 'has-arrow',
    ddclass: '',
    extralink: false,
    submenu: [
      {
        path: '/publishers',
        title: 'Publishers',
        icon: 'icon-list',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      }, {
        path: '/ad-space',
        title: 'Ad Spaces',
        icon: 'icon-screen-desktop',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Advertiser',
    icon: 'icon-briefcase',
    class: 'has-arrow',
    ddclass: '',
    extralink: false,
    submenu: [
      {
        path: '/companies',
        title: 'Companies',
        icon: 'icon-briefcase',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      }, {
        path: '/campaigns',
        title: 'Campaigns',
        icon: 'icon-layers',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      }, {
        path: '/campaign-commissions',
        title: 'Campaign Commissions',
        icon: 'icon-equalizer',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Users',
    icon: 'icon-people',
    class: 'has-arrow',
    ddclass: '',
    extralink: false,
    submenu: [
      {
        path: '/user',
        title: 'User',
        icon: 'icon-user',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/role',
        title: 'Role',
        icon: ' icon-key',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Management',
    icon: 'icon-wrench',
    class: 'has-arrow',
    ddclass: '',
    extralink: false,
    submenu: [
      {
        path: '/contracts',
        title: 'Contracts',
        icon: 'icon-grid',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/level-commissions',
        title: 'Level Commissions',
        icon: ' icon-grid',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Tools',
    icon: 'icon-wrench',
    class: 'has-arrow',
    ddclass: '',
    extralink: false,
    submenu: [
      {
        path: '/deeplink',
        title: 'Deeplink',
        icon: 'icon-link',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
    ]
  }, {
    path: '/',
    title: 'Reports',
    icon: 'icon-chart',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  }, {
    path: '/',
    title: 'Settings',
    icon: 'mdi mdi-settings',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  }
];
