import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {ErrorService} from '../modules/common/error.service';
import * as shajs from 'sha.js';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient, private errorService: ErrorService) { }

  login() {
    let authUrl = environment.auth_url + '/oauth/authorize';
    const state = this.createState();
    this.setItem('state', state);
    const parameters = this.params({
      'client_id' : environment.client_id,
      'scope' : 'user_info',
      'response_type' : 'code',
      'redirect_uri' : environment.redirect_url,
      'state' : state
    });

    authUrl += '?' + parameters;
    window.location.href = authUrl;
  }

  logoutWithoutCache() {
    this.removeItem('access_token');
    this.removeItem('refresh_token');
    this.removeItem('expire');

    let authUrl = environment.auth_url + '/logout';
    const parameters = {
      'redirect_uri' : environment.redirect_url,
    };
    authUrl += '?' + this.params(parameters);

    window.location.href = authUrl;
  }

  logout() {
    const access_token = this.getItem('access_token');
    this.clearAuth(access_token).subscribe(response => {
      if (response.status === 'error') {
        console.log(response.message);
      } else if (response.status === 'fail') {
        console.log(response.data);
      } else {
        this.logoutWithoutCache();
      }
    });
  }

  getAccessToken(code) {
    const authUrl = environment.auth_url + '/oauth/token';
    const body = this.params({
      'grant_type' : 'authorization_code',
      'code' : code,
      'redirect_uri' : environment.redirect_url
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(environment.client_id + ':' + environment.client_secret)
    });
    return this.http.post<any>(authUrl, body, {headers}).pipe(catchError(this.errorService.handleError('getAccessToken', {})));
  }

  clearAuth(access_token) {
    const apiUrl = environment.api_url + '/auth/clear';

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + access_token
    });
    return this.http.get<any>(apiUrl, {headers}).pipe(catchError(this.errorService.handleError('clearAuth', {})));
  }

  checkAuth(access_token, module, action) {
    const apiUrl = environment.api_url + '/auth/check';

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + access_token
    });

    const params = new HttpParams()
      .set('module', module)
      .set('action', action);
    return this.http.get<any>(apiUrl, {headers, params}).pipe(catchError(this.errorService.handleError('checkAuth', {})));
  }

  cacheAuth(params) {
    const apiUrl = environment.api_url + '/auth/cache';

    const body = {
      'refresh_token' : params.refresh_token
    };

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + params.access_token
    });
    return this.http.post<any>(apiUrl, body, {headers}).pipe(catchError(this.errorService.handleError('cacheAuth', {})));
  }

  getAccessTokenFromLocal() {
    return localStorage.getItem('access_token');
  }

  removeItem(key) {
    localStorage.removeItem(key);
  }

  setItem(key, value) {
    localStorage.setItem(key, value);
  }

  getItem(key) {
    return localStorage.getItem(key);
  }

  params(object) {
    const parameters = [];
    for (const property in object) {
      if (object.hasOwnProperty(property)) {
        parameters.push(encodeURI(property + '=' + object[property]));
      }
    }

    return parameters.join('&');
  }

  createState() {
    return shajs('sha256').update('42').digest('hex');
  }

  checkExpireTime() {
    const expire = this.getItem('expire');
    if (expire !== null) {
      const now = Math.floor(Date.now() / 1000);
      if ((+expire - now) < 0) {
        this.refreshToken().subscribe(response => {
          if (response.status === 'success') {
            this.setItem('access_token', response.data.access_token);
          } else {
            console.log('status: ' + response.status + '. message: ' + response.message);
            this.logoutWithoutCache();
          }
        });
      }
    }
  }

  refreshToken() {
    const refresh_token = this.getItem('refresh_token');
    const access_token = this.getItem('access_token');
    const apiUrl = environment.api_url + '/auth/refresh';

    const body = {
      'refresh_token' : refresh_token
    };

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + access_token
    });
    return this.http.post<any>(apiUrl, body, {headers}).pipe(catchError(this.errorService.handleError('refresh', {})));
  }
}
