import {Component, AfterViewInit, EventEmitter, Output} from '@angular/core';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {AuthenticationService} from '../../authentication/authentication.service';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  lang = 'en';

  constructor(private modalService: NgbModal, private authService: AuthenticationService, private translate: TranslateService) {
  }

  ngAfterViewInit() {
  }

  logout() {
    this.authService.logout();
  }

  switchLanguage(lang: any) {
    this.lang = lang;
    this.translate.use(lang);
  }
}
